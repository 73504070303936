import { ActiveMonitoringDTO } from '@/dtos/ActiveMonitoringDTO';
import { Get, Patch, Post } from './BaseService';
import AuditoriaDTO from "@/dtos/AuditoriaDTO";
import AuditResponseDTO from "@/dtos/AuditResponseDTO";
import { ProcessingDTO } from '@/dtos/ProcessingDTO';
import ProctoringResponseDTO from '@/dtos/ProctoringResponseDTO';
import { RulesDTO } from '@/dtos/RulesDTO';
import { ConfigDTO } from '@/dtos/ConfigsDTO';
import ConfigurationResponseDTO from '@/dtos/ConfigurationResponseDTO';
import LoginProctoringDTO from '@/dtos/LoginProctoringDTO';
import UserDTO from '@/dtos/UserDTO';
import UpdateBiometryDTO from '@/dtos/UpdateBiometryDTO';
import UpdateDocumentResponseDTO from '@/dtos/UpdateDocumentResponseDTO';

export default {

    Audit: (id: string, data: AuditoriaDTO[]) => {
        return Post<AuditoriaDTO[], AuditResponseDTO>(`api/Proctoring/${id}/audit`, data);
    },

    AuditImage: (id: string, data: AuditoriaDTO[]) => {
        return Post<AuditoriaDTO[], AuditResponseDTO>(`api/Proctoring/${id}/audit-image`, data);
    },
    PostReprocessing: (proctoringId: string) => {
        return Post<any, any>(`api/Proctoring/reprocess/${proctoringId}`, {});
    },
    GetConfiguration: () => {
        return Get<ConfigurationResponseDTO[]>(`api/Proctoring/ExamConfiguration`);
    },

    GetProctoring: (id: string) => {
        return Get<ProctoringResponseDTO>(`api/Proctoring/${id}`);
    },

    GetConfigs: () => {
        const configs = Get<ConfigDTO>(`api/Proctoring/Client/params`);
        return configs;
    },

    GetRules: () => {
        const configs = Get<RulesDTO[]>(`api/Proctoring/Client/rules`);
        return configs;
    },
    PostActiveMonitoring: (activeMonitoringDTO: ActiveMonitoringDTO) => {
        const configs = Post<ActiveMonitoringDTO, ActiveMonitoringDTO>(`api/Proctoring/Client/params`, activeMonitoringDTO);
        return configs;
    },
    // Processing
    PostProcessing: (processingDTO: ProcessingDTO) => {
        const configs = Post<ProcessingDTO, ProcessingDTO>(`api/Proctoring/Client/image-sensibility`, processingDTO);
        return configs;
    },
    // Rules
    PostRules: (rules: RulesDTO[]) => {
        const configs = Post<RulesDTO[], RulesDTO[]>(`api/Proctoring/Client/rules`, rules);
        return configs;
    },

    LoginProctoring: (data: LoginProctoringDTO) => {
        return Post<LoginProctoringDTO, UserDTO>('api/Proctoring/auth', data);
    },
    UpdateUserFace: (data: UpdateBiometryDTO) => {
        return Patch<UpdateBiometryDTO, UpdateDocumentResponseDTO>(
            "api/Proctoring/User/face",
            data
        );
    },
};
import { Patch, Post, Get } from './BaseService';

import LoginDTO from '@/dtos/LoginDTO';
import LoginProctoringDTO from '@/dtos/LoginProctoringDTO';
import UserDTO from '@/dtos/UserDTO';
import UpdateBiometryDTO from '@/dtos/UpdateBiometryDTO';
import UpdateDocumentResponseDTO from '@/dtos/UpdateDocumentResponseDTO';
import UserDemoDTO from '@/dtos/UserDemoDTO';
import UserCreateDTO from '@/dtos/UserCreateDTO';
import ExaminadorDTO from '@/dtos/ExaminadorDTO';
import BiopassTemplateDTO from '@/dtos/BiopassTemplateDTO';

const UsuarioService = {
    Login: (data: LoginDTO) => {
        return Post<LoginDTO, UserDTO>('api/Auth', data);
    },
    getUser: (id: string) => {
        return Get<UserDTO>(`api/User/${id}`);
    },
    getAllExaminers: () => {
        return Get<ExaminadorDTO[]>(`api/User/GetAllExaminers`);
    },
    getUserCpf: (cpf: string) => {
        return Get<UserDTO>(`api/User/cpf/${cpf}`);
    },
    PostDemoUser: (query: UserDemoDTO) => {
        return Post<any, UserDemoDTO>(`api/User`, query);
    },
    PostUser: (query: UserCreateDTO) => {
        return Post<any, UserCreateDTO>(`/api/User/admin`, query);
    },
    PostVerifyEmail: (data: string) => {
        return Post<any, any>(`api/User/verifyEmail/${data}`, {});
    },
    PostReset: (cpf: string) => {
        return Post<any, any>(`/api/User/reset/${cpf}`, {});
    },
    PatchUpdatePassword: (data: {
        cpf: string,
        password: string
    }) => {
        return Patch<any, any>(`/api/User/password`, data);
    },
    CreateBiopassUserFace: (data: BiopassTemplateDTO) => {
        return Post<any, BiopassTemplateDTO>(
            "api/BiopassID/Create",
            data
        );
    },
};

export default UsuarioService;


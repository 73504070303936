import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.VUE_APP_GCP_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.VUE_APP_GCP_PROJECT_ID}-default-rtdb.firebaseio.com`,
    projectId: process.env.VUE_APP_GCP_PROJECT_ID,
    storageBucket: `${process.env.VUE_APP_GCP_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};



export const app = initializeApp(firebaseConfig);
export const authApp = getAuth(app);




import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { authApp } from "@/firebase";

const auth = authApp;

export default {
    createUser: async (email: string, password: string) => {
        
        return createUserWithEmailAndPassword(auth, email, password)
    },

    login: async (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password);
    },

    watchUser: async () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("User is logged in", user);
            } else {
                console.log("User is not logged in");
            }
        });
    }
};
